var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.pickl && Object.keys(_vm.pickl).length > 0
      ? _c(
          "h2",
          { staticStyle: { display: "block", "text-align": "center" } },
          [_vm._v("Task Overview")]
        )
      : _vm._e(),
    _vm.pickl && Object.keys(_vm.pickl).length > 0
      ? _c(
          "div",
          {
            staticClass: "pickl-details green-border-box",
            staticStyle: { margin: "2%", padding: "1%" }
          },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-xs-12 col-md-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "green-border-box white-bg text-center first-block"
                  },
                  [
                    _c("div", { staticClass: "top-part" }, [
                      _c("div", { staticClass: "posRel" }, [
                        _c(
                          "div",
                          { staticClass: "grey-logo-circle company-logo" },
                          [
                            _c("img", {
                              staticClass: "img-in-circle",
                              attrs: {
                                src:
                                  _vm.pickl.store_location.store.image +
                                  "?w=150&q=50"
                              },
                              on: {
                                error: function($event) {
                                  _vm.pickl.store_location.store.image =
                                    "/img/default_store_image.png"
                                }
                              }
                            })
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "grey-logo-circle product-logo" },
                          [
                            _c("img", {
                              staticClass: "img-in-circle",
                              attrs: {
                                src: _vm.pickl.brand.logo + "?w=150&q=50"
                              },
                              on: {
                                error: function($event) {
                                  _vm.pickl.brand.logo = "/img/pickl-logo.png"
                                }
                              }
                            })
                          ]
                        ),
                        _c("h4", [
                          _vm._v(_vm._s(_vm.pickl.store_location.store.name))
                        ]),
                        _c("h5", [_vm._v(_vm._s(_vm.pickl.brand.name))])
                      ])
                    ]),
                    _c("div", { staticClass: "middle-part" }, [
                      _c(
                        "div",
                        {
                          staticClass: "address",
                          attrs: { title: _vm.pickl.store_location.address }
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.pickl.store_location.address) +
                              "\n              "
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "bottom-part" }, [
                      _c(
                        "div",
                        { staticClass: "col-xs-12 col-sm-6 pickl-date" },
                        [
                          _c("img", {
                            attrs: { src: "/img/Alerts/section_icon.png" }
                          }),
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.pickl.product.section) +
                              "\n              "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-xs-12 col-sm-6 pickl-time" },
                        [
                          _c("img", {
                            attrs: { src: "/img/Picklr/time_icon.png" }
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("fullDate")(_vm.pickl.updated_at)) +
                              ", " +
                              _vm._s(
                                _vm._f("formattedPhpTime")(_vm.pickl.updated_at)
                              ) +
                              "\n              "
                          )
                        ]
                      ),
                      _c("div", { staticClass: "clearfix" })
                    ])
                  ]
                )
              ]),
              _c("div", [
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("div", { staticClass: "green-border-box posRel" }, [
                    _c("h2", { staticStyle: { "text-align": "center" } }, [
                      _vm._v("Product Details")
                    ]),
                    _c("br"),
                    _c("br"),
                    _c("h4", { staticClass: "plain-center-header" }, [
                      _vm._v("Product Name: "),
                      _c("strong", [_vm._v(_vm._s(_vm.pickl.product.name))])
                    ]),
                    _c(
                      "div",
                      { staticClass: "row attr-imgs" },
                      [
                        _c(
                          "viewer",
                          {
                            attrs: {
                              images: _vm.pickl.product.images,
                              options: _vm.viewerOptions
                            }
                          },
                          _vm._l(_vm.pickl.product.images, function(
                            photo,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: _vm.pickl.product.id + "-" + index,
                                staticClass: "col-xs-6 col-md-4 photo"
                              },
                              [
                                _c("div", { staticClass: "img-contain" }, [
                                  _c("img", {
                                    staticClass: "vertical-center-transform",
                                    attrs: {
                                      src: photo.image + _vm.lowSrcQuery,
                                      alt:
                                        photo.description === null
                                          ? _vm.pickl.product.name
                                          : photo.description
                                    }
                                  })
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "attr-time text-center",
                                    attrs: { title: photo.description || "" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(photo.description || " ") +
                                        "\n                    "
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("div", { staticClass: "green-border-box posRel" }, [
                    _c("h2", { staticStyle: { "text-align": "center" } }, [
                      _vm._v("Task Images")
                    ]),
                    _c(
                      "div",
                      { staticClass: "attr-imgs" },
                      [
                        _c(
                          "viewer",
                          {
                            attrs: {
                              images: _vm.taskImages,
                              options: _vm.viewerOptions
                            }
                          },
                          [
                            _vm._l(_vm.user_pickl_photos, function(
                              photo,
                              index
                            ) {
                              return _c("div", { key: photo.name }, [
                                photo.task
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "col-xs-6 col-md-4 photo"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "img-contain" },
                                          [
                                            _c("img", {
                                              staticClass:
                                                "vertical-center-transform",
                                              attrs: {
                                                src:
                                                  photo.image + _vm.lowSrcQuery,
                                                alt: photo.task
                                                  ? photo.task.name
                                                  : "Extra Image"
                                              }
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "img-overlay" },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "img-count" },
                                                  [_vm._v(_vm._s(index + 1))]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "vertical-center-transform"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        photo.task
                                                          ? photo.task.name
                                                          : "Extra Image"
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "attr-time" },
                                          [
                                            _c("img", {
                                              staticClass: "mr3",
                                              attrs: {
                                                src:
                                                  "/img/Alerts/timeGreen_icon.png"
                                              }
                                            }),
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm._f("formattedPhpTime")(
                                                    photo.created_at
                                                  )
                                                )
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            }),
                            _vm._l(_vm.user_pickl_photos, function(
                              photo,
                              index
                            ) {
                              return _c("div", { key: photo.id }, [
                                !photo.task
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "col-xs-6 col-md-4 photo"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "img-contain" },
                                          [
                                            _c("img", {
                                              staticClass:
                                                "vertical-center-transform",
                                              attrs: {
                                                src:
                                                  photo.image + _vm.lowSrcQuery,
                                                alt: photo.task
                                                  ? photo.task.name
                                                  : "Extra Image"
                                              }
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "img-overlay" },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "img-count" },
                                                  [_vm._v(_vm._s(index + 1))]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "vertical-center-transform"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        photo.task
                                                          ? photo.task.name
                                                          : "Extra Image"
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "attr-time" },
                                          [
                                            _c("img", {
                                              staticClass: "mr3",
                                              attrs: {
                                                src:
                                                  "/img/Alerts/timeGreen_icon.png"
                                              }
                                            }),
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm._f("formattedPhpTime")(
                                                    photo.created_at
                                                  )
                                                )
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            })
                          ],
                          2
                        ),
                        !_vm.isSadmin && _vm.user_pickl_photos.length === 0
                          ? _c("div", { staticClass: "overlay" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vertical-center-transform normal-text"
                                },
                                [
                                  _vm._v(
                                    "\n                    Task images not available\n                  "
                                  )
                                ]
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ])
              ])
            ])
          ]
        )
      : _vm._e(),
    !_vm.loading && Object.keys(_vm.pickl).length == 0
      ? _c("div", { staticClass: "outer-box vertical-center-transform" }, [
          _c("h2", { staticStyle: { padding: "20rem" } }, [
            _vm._v("Task Not Found!")
          ])
        ])
      : _vm._e(),
    _vm.loading
      ? _c("div", { staticClass: "outer-box vertical-center-transform" }, [
          _c("h2", { staticStyle: { padding: "20rem" } }, [
            _vm._v("Loading Task Details....")
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }