<template>
  <div>
      <h2 style="display:block;text-align:center;" v-if="pickl && Object.keys(pickl).length > 0">Task Overview</h2>
      <div class="pickl-details green-border-box" style="margin:2%;padding:1%;" v-if="pickl && Object.keys(pickl).length > 0">
        <div class="row">
          <div class="col-xs-12 col-md-12">
            <div class="green-border-box white-bg text-center first-block">
              <div class="top-part">
                <div class="posRel">
                  <div class="grey-logo-circle company-logo">
                    <img
                      class="img-in-circle"
                      :src="
                        pickl.store_location.store.image + '?w=150&q=50'
                      "
                      @error="
                        pickl.store_location.store.image =
                          '/img/default_store_image.png'
                      "
                    />
                  </div>
                  <div class="grey-logo-circle product-logo">
                    <img
                      class="img-in-circle"
                      :src="pickl.brand.logo + '?w=150&q=50'"
                      @error="pickl.brand.logo = '/img/pickl-logo.png'"
                    />
                  </div>
                  <h4>{{ pickl.store_location.store.name }}</h4>
                  <h5>{{ pickl.brand.name }}</h5>
                </div>
              </div>
              <div class="middle-part">
                <div class="address" :title="pickl.store_location.address">
                  {{ pickl.store_location.address }}
                </div>
              </div>
              <div class="bottom-part">
                <div class="col-xs-12 col-sm-6 pickl-date">
                  <img src="/img/Alerts/section_icon.png" />
                  {{ pickl.product.section }}
                </div>
                <div class="col-xs-12 col-sm-6 pickl-time">
                  <img src="/img/Picklr/time_icon.png" /> {{ pickl.updated_at |fullDate }}, {{pickl.updated_at | formattedPhpTime}}
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
          <div>
            <div class="col-sm-6">
              <div class="green-border-box posRel">
                <h2 style="text-align:center;">Product Details</h2><br/><br/>
                <h4 class="plain-center-header">Product Name: <strong>{{ pickl.product.name }}</strong></h4>
                <div class="row attr-imgs">
                  <viewer
                    :images="pickl.product.images"
                    :options="viewerOptions"
                  >
                    <div
                      class="col-xs-6 col-md-4 photo"
                      v-for="(photo, index) in pickl.product.images"
                      :key="pickl.product.id + '-' + index"
                    >
                      <div class="img-contain">
                        <img
                          class="vertical-center-transform"
                          :src="photo.image + lowSrcQuery"
                          :alt="
                            photo.description === null
                              ? pickl.product.name
                              : photo.description
                          "
                        />
                        <!-- <div class="img-overlay">
                                    <span class="img-count">{{index + 1}}</span>
                                    <span class="vertical-center-transform">{{photo.task?photo.task.name:'Extra Image'}}</span>
                                  </div> -->
                      </div>
                      <div
                        class="attr-time text-center"
                        :title="photo.description || ''"
                      >
                        {{ photo.description || '&nbsp;' }}
                      </div>
                    </div>
                  </viewer>
                </div>
            </div>
            </div>
            <div class="col-sm-6">
              <div class="green-border-box posRel">
                <h2 style="text-align:center;">Task Images</h2>
                <div class="attr-imgs">
                  <viewer :images="taskImages" :options="viewerOptions">
                    <div
                      v-for="(photo, index) in user_pickl_photos"
                      :key="photo.name"
                    >
                      <div class="col-xs-6 col-md-4 photo" v-if="photo.task">
                        <div class="img-contain">
                          <img
                            class="vertical-center-transform"
                            :src="photo.image + lowSrcQuery"
                            :alt="
                              photo.task ? photo.task.name : 'Extra Image'
                            "
                          />
                          <div class="img-overlay">
                            <span class="img-count">{{ index + 1 }}</span>
                            <span class="vertical-center-transform">{{
                              photo.task ? photo.task.name : 'Extra Image'
                            }}</span>
                          </div>
                        </div>
                        <span class="attr-time"
                          ><img
                            src="/img/Alerts/timeGreen_icon.png"
                            class="mr3"
                          />
                          {{ photo.created_at | formattedPhpTime }}</span
                        >
                        <!-- <span class="attr-time pull-right"><router-link :to="'/sadmin/pickls/' + pickl.id + '/tasks/' + photo.task.id + '/competitions'"><img src="/img/Pickl details/section_icon.png" class="mr3" /></router-link></span> -->
                      </div>
                    </div>
                    <div
                      v-for="(photo, index) in user_pickl_photos"
                      :key="photo.id"
                    >
                      <div class="col-xs-6 col-md-4 photo" v-if="!photo.task">
                        <div class="img-contain">
                          <img
                            class="vertical-center-transform"
                            :src="photo.image + lowSrcQuery"
                            :alt="
                              photo.task ? photo.task.name : 'Extra Image'
                            "
                          />
                          <div class="img-overlay">
                            <span class="img-count">{{ index + 1 }}</span>
                            <span class="vertical-center-transform">{{
                              photo.task ? photo.task.name : 'Extra Image'
                            }}</span>
                          </div>
                        </div>
                        <span class="attr-time"
                          ><img
                            src="/img/Alerts/timeGreen_icon.png"
                            class="mr3"
                          />
                          {{ photo.created_at | formattedPhpTime }}</span
                        >
                      </div>
                    </div>
                  </viewer>
                  <div
                    class="overlay"
                    v-if="!isSadmin && user_pickl_photos.length === 0"
                  >
                    <div class="vertical-center-transform normal-text">
                      Task images not available
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="outer-box vertical-center-transform" v-if="!loading && Object.keys(pickl).length == 0">
        <h2 style="padding:20rem;">Task Not Found!</h2>
      </div>
      <div class="outer-box vertical-center-transform" v-if="loading">
        <h2 style="padding:20rem;">Loading Task Details....</h2>
      </div>
  </div>
</template>
<script>
export default {
  name: 'PicklOverview',
  data: function () {
    return {
      loading: true,
      openForDiscussion: 0,
      isSadmin: false,
      pickl: {},
      lowSrcQuery: '?w=200&q=80&h=200&fit=crop',
      viewerOptions: {
        toolbar: {
          zoomIn: 1,
          zoomOut: 1,
          oneToOne: 0,
          reset: 0,
          prev: {
            size: 'large'
          },
          play: 0,
          next: {
            size: 'large'
          },
          rotateLeft: 1,
          rotateRight: 1,
          flipHorizontal: 0,
          flipVertical: 0
        },
        filter: function (image) {
          return !image.src.endsWith('/img/Alerts/timeGreen_icon.png')
        },
        title: [
          1,
          function (image, imageData) {
            return image.alt
          }
        ],
        url: image => {
          return image.src.replace(this.lowSrcQuery, '')
        }
      }
    }
  },
  mounted () {
    this.getPicklDetails()
  },
  computed: {
    getAddedTaskDataPoints () {
      let addedTaskDataPoints = []
      this.taskDataPoints.forEach(dataPoint => {
        if (typeof dataPoint.value !== 'undefined') {
          addedTaskDataPoints.push({
            task_data_point_id: dataPoint.id,
            text_answer: dataPoint.value
          })
        }
      })
      return addedTaskDataPoints
    },
    dummyPicklImages () {
      if (this.user_pickl_photos.length > 0) {
        let count = 6 - this.user_pickl_photos.length
        if (count < 0) {
          return 0
        } else {
          return count
        }
      } else {
        return 6
      }
    },
    dummyProductImages () {
      if (this.pickl.product.images && this.pickl.product.images.length > 0) {
        let count = 6 - this.pickl.product.images.length
        if (count < 0) {
          return 0
        } else {
          return count
        }
      } else {
        return 6
      }
    },
    user_pickl_photos () {
      if (
        this.pickl &&
        this.pickl.user_pickl &&
        this.pickl.user_pickl.user_pickl_photos &&
        this.pickl.status === 'APPROVED') {
        return this.pickl.user_pickl.user_pickl_photos
      } else {
        return []
      }
    },
    productImages () {
      let images = []
      if (
        this.pickl &&
        this.pickl.product &&
        this.pickl.product.images &&
        this.pickl.product.images.length > 0
      ) {
        this.pickl.product.images.forEach(x => {
          images.push({
            path: x.image,
            caption:
              x.description === null ? this.pickl.product.name : x.description
          })
        })
      }
      return images
    },
    taskImages () {
      let images = []
      this.user_pickl_photos.forEach(x => {
        images.push({
          path: x.image,
          caption: x.task ? x.task.name : 'Extra Image'
        })
      })
      return images
    }
  },
  methods: {
    getPicklDetails () {
      this.$http.get('brand_shared_pickls/' + this.$route.params.sharedCode).then((response) => {
        this.pickl = response.body
        this.loading = false
      }).finally((d) => {
        this.loading = false
      })
    },

    getLogName: function (type) {
      if (this.$store.state.userData && this.isSadmin && type === 'completed') { return 'Awaiting Approval' } else return type
    }
  }
}
</script>

<style scoped>
.vertical-center-transform {
  width: 100%;
  text-align: center;
}

.green-border-box {
  margin-bottom: 15px;
}

.border-circle {
  width: 19%;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  font-size: 15px;
  font-weight: 500;
  position: relative;
  display: inline-block;
  margin-bottom: 44px;
}

.border-circle:before {
  content: ' ';
  padding-top: 100%;
  display: block;
}

hr.journey-string {
  width: 5%;
  margin: 10% 4px 0;
  vertical-align: top;
  border-width: 2px;
  border-color: #d9d9d9;
  display: inline-block;
}

.modal-content .green-border-box .plain-center-header {
  font-weight: 500;
  font-size: 22px;
  color: #333333;
  margin-top: 0;
  margin-bottom: 15px;
  text-align: center;
}

.circle-label {
  font-size: 16px;
  color: black;
  width: 100%;
  text-align: center;
  display: block;
  position: absolute;
  bottom: -50px;
  left: 0;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 22px;
  height: 44px;
  overflow: hidden;
}

.circle-label:last-child {
  padding-right: 0;
}

.completion-time {
  border-radius: 360px;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 13px;
  padding: 5px 15px;
  background: #f02a38;
  background: -webkit-linear-gradient(left, #f02a38 0%, #ff434c 100%);
  background: -moz-linear-gradient(left, #f02a38 0%, #ff434c 100%);
  background: linear-gradient(to right, #f02a38 0%, #ff434c 100%);
}

.rating img:last-child {
  margin-right: 0;
}

.rating strong {
  font-weight: 500;
  font-size: 18px;
  color: black;
  margin-right: 5px;
}

.top-part {
  padding: 15px;
}

.middle-part {
  padding: 0 15px 15px;
}

.top-part h4,
.top-part h5 {
  padding: 0 75px;
  margin: 0;
}

.top-part h4 {
  font-weight: 500;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 30px;
}

.top-part h5 {
  font-size: 17px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 22px;
  margin-bottom: 8px;
}

.product-logo {
  left: auto;
  right: 0;
}

.middle-part .address {
  font-size: 17px;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  margin-bottom: 10px;
}

/* .middle-part .address:first-of-type {
  min-height: 48px;
} */

.middle-part .status {
  font-size: 19px;
  font-weight: 500;
}

.bottom-part {
  padding: 10px 0;
  background: #f7f8f9;
  font-weight: 500;
  font-size: 19px;
  color: rgba(0, 0, 0, 0.8);
  vertical-align: text-top;
  line-height: 35px;
}

.bottom-part img {
  vertical-align: text-top;
  margin-right: 3px;
}

.bottom-part .pickl-date {
  border-right: 1px solid #cfd1d3;
}

.rating {
  background-color: #f7f8f9;
  /* padding: 30px 20px; */
  float: left;
  width: 100%;
  min-height: 233px;
}

.issues {
  min-height: 233px;
}

.rating img {
  width: 19px;
  height: 17px;
  margin-right: 3px;
}

.rating img:last-child {
  margin-right: 0;
}

.attr-imgs {
  padding: 20px 10px 0;
}

.photo {
  padding: 0 10px;
  margin-bottom: 40px;
}

.img-contain {
  position: relative;
  margin-bottom: 10px;
  display: block;
  width: 100%;
  overflow: hidden;
}

.img-contain:before {
  padding-top: 100%;
  display: block;
  content: ' ';
}

.img-contain img {
  width: 100%;
  height: auto;
  cursor: pointer;
  /* max-width: 100%;
  max-height: 100%; */
}

.img-contain .img-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  /* background-color: rgba(0, 0, 0, 0.3); */
  font-weight: 500;
  font-size: 22px;
  text-transform: capitalize;
  pointer-events: none;
}

.img-contain .img-overlay .img-count {
  border-radius: 50%;
  background-color: #00e05e;
  border: 2px solid white;
  font-size: 17px;
  width: 25px;
  height: 25px;
  display: block;
  text-align: center;
  position: absolute;
  top: 5px;
  left: 5px;
  line-height: 21px;
}

.attr-time {
  font-size: 17px;
  color: #333333;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.normal-text {
  color: rgba(0, 0, 0, 0.9);
  font-size: 18px;
}

label {
  width: 100%;
  color: rgba(89, 89, 89, 0.9);
  font-size: 17px;
  margin-top: 0;
  margin-bottom: 0;
}

.rating label {
  margin-top: 30px;
}

.btn-lg-grey,
.btn-lg-green {
  width: 200px;
}

textarea {
  margin-bottom: 0;
}

.rating h5 {
  font-weight: 500;
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 14px;
}

.rate-picklr {
  cursor: pointer;
  margin-bottom: 45px;
}

.rate-picklr span {
  display: inline-block;
}

.rate-picklr img {
  width: 33px;
  height: 31px;
  margin: 0 8px;
}

.showOnMobile {
  display: none;
}

.address img {
  vertical-align: text-top;
}

.img-contain .img-overlay span:last-child {
  padding: 3px;
  background: rgba(0, 0, 0, 0.8);
}

.overlay {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.overlay .vertical-center-transform {
  color: white;
}

.first-block {
  min-height: 230px;
}

.product-details .photo {
  margin-bottom: 14px;
}

.product-details .attr-imgs {
  padding-top: 0;
}

.product-details .missing-image {
  border: 1px dashed black;
}

@media only screen and (min-width: 1096px) and (max-width: 1199px) {
  .bottom-part {
    font-size: 15px;
    line-height: 30px;
  }

  .bottom-part > div {
    padding-left: 0;
    padding-right: 0;
  }

  .bottom-part img {
    height: 17px;
    width: auto;
  }
}
.modal-dialog.modal-lg {
  max-width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .border-circle {
    font-size: 13px;
    line-height: 17px;
  }

  .border-circle .circle-label {
    font-size: 13px;
  }

  .img-contain .img-overlay {
    font-size: 17px;
  }
}

@media only screen and (max-width: 767px) {
  .bottom-part {
    padding: 0 15px;
  }

  .bottom-part .pickl-date,
  .bottom-part .pickl-time {
    padding: 10px 0;
  }

  .bottom-part .pickl-date {
    border-bottom: 1px solid #cfd1d3;
    border-right: none;
  }

  .rating {
    min-height: auto;
  }

  .issues {
    min-height: auto;
  }
}

@media only screen and (max-width: 575px) {
  .company-logo {
    left: 20%;
  }

  .product-logo {
    right: 20%;
  }

  .top-part h4 {
    padding: 0;
    padding-top: 70px;
  }
  .top-part h5 {
    padding: 0;
  }

  .showOnMobile {
    display: inline;
  }

  /* .rating {
    padding: 15px;
  } */

  .rating strong {
    font-size: 16px;
  }

  .rated img {
    width: 15px;
    height: auto;
  }

  .img-contain .img-overlay {
    font-size: 17px;
  }

  .border-circle {
    font-size: 14px;
    line-height: 17px;
    min-width: 75px;
    min-height: 75px;
    display: block;
    margin-bottom: 0;
  }

  .border-circle .circle-label {
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + 20px);
    width: auto;
    text-align: left;
    bottom: auto;
    height: auto;
  }

  hr.journey-string {
    width: 100%;
    border-width: 1px;
    margin: 25px 0;
  }

  .btn-lg-grey,
  .btn-lg-green {
    width: 120px;
  }
}

.review-attention strong {
  color: #cb4f40;
}
.team-comment {
  float: left;
  text-align: left;
  border: 1px solid #12db6d;
  margin: 10px;
  border-radius: 10px;
  padding: 5px;
  background: ghostwhite;
}
.your-comment {
  float: right;
  text-align: right;
  border: 1px solid #12db6d;
  margin: 10px;
  padding: 5px;
  border-radius: 10px;
  background: #e8fff2;
}
.outer-box {
  margin: 0 auto;
  max-width: 1366px;
  width: 100%;
  background-image: url("/img/background-portrait.png");
  background-size: 50% 100%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.pickl-logo {
  margin-bottom: 30px;
  width: 120px;
  height: 120px;
  border-radius: 7px;
}

.login-box {
  margin: 5% auto 0;
  padding: 10px 90px 20px;
}

.video-out {
  padding-left: 0;
    left: auto;
    right: 0;
}

.video-box {
  margin: 0 auto;
  /* margin: 0 0 20px; */
  padding: 25px 0 15px;
}

.video-box h3 {
  font-weight: 700;
  text-align: center;
  font-size: 44px;
  color: black;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  .login-box {
    padding: 10px 35px 20px;
  }
}

@media only screen and (max-width: 991px) {
  .outer-box {
    background-size: 100% 100vh;
        background-repeat: repeat-y;
        position: static;
        transform: none;
  }

  .video-out {
    padding-left: 15px;
        min-height: auto;
        position: static;
        transform: none;
    }

    .video-box {
        margin-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .login-box {
    padding: 15px;
  }

  .video-box h3 {
    font-size: 36px;
        padding: 15px;
    }
}

@media only screen and (max-height: 660px) {
    .outer-box {
        position: static;
        transform: none;
  }
}
select,
select:hover {
  border: 1px solid #6f3053;
  border-radius: 3px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 20px;
  color: black;
  width: 100%;
  padding: 14px 42px 14px 16px;
  height: 50px;
  background-position: right 11px center;
  background-size: 20px 12px;
  background-image: url("/img/Create Pickl/selectproductDropdown_icon.png");
  background-color: white;
}
</style>
